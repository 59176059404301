<template>
  <div>
    <div id="image-container" @click="addPoint">
      <img :src="src" class="w-100" ref="image" />
      <div
        v-for="(point, index) in points"
        :key="index"
        class="point"
        :style="{ left: `${point.x}%`, top: `${point.y}%` }"
      ></div>
      <ProductSelectorToast
        :position-x="posX"
        :position-y="posY"
        v-model:show="showToast"
      />
    </div>
  </div>
</template>

<script>
import ProductSelectorToast from "./inputs/ProductSelectorToast.vue";

export default {
  name: "ImageAnnotorious",
  components: { ProductSelectorToast },
  props: {
    id: String,
    src: String,
  },
  data() {
    return {
      points: [],
      posX: 0,
      posY: 0,
      showToast: false,
    };
  },
  methods: {
    addPoint(event) {
      const rect = this.$refs.image.getBoundingClientRect();
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;

      this.points.push({ x: xPercent, y: yPercent });
      this.posX = xPercent;
      this.posY = yPercent;
      this.showToast = true;
      console.log(`X: ${xPercent}%, Y: ${yPercent}%`);
    },
  },
};
</script>
<style scoped>
#image-container {
  position: relative;
  display: inline-block;
}
.point {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #8600f3;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>
