<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row mt-3">
            <div class="col-md-4 col-lg-3">
              <div class="left-nav">
                <h2 class="semibold-16 mt-3 mb-1 ms-3">Pages</h2>
                <div class="items">
                  <button
                    v-for="page in pages"
                    :key="page.id"
                    class="btn w-100 text-start"
                    :class="
                      selectedPage?.id === page.id
                        ? 'btn-primary'
                        : 'btn-outline-secondary'
                    "
                    v-on:click="selectService('Google', page)"
                  >
                    <BaseIcon
                      :name="getLogo(page.integration_service)"
                      type="social"
                      class="logo-icon"
                    />
                    <span>{{ page.name }}</span>
                    <span class="badge ms-3" :class="getTypeClass(page.type)">{{
                      getTypeName(page.type)
                    }}</span>
                  </button>
                </div>
              </div>
              <h2 class="semibold-16 mt-3 mb-1 ms-3">Platforms</h2>
              <div class="left-nav">
                <div class="items">
                  <button
                    class="btn w-100 text-start"
                    :class="
                      selectedService === 'Google' && selectedPage === null
                        ? 'btn-primary'
                        : 'btn-outline-secondary'
                    "
                    v-on:click="selectService('Google')"
                  >
                    <BaseIcon
                      name="google-maps"
                      type="social"
                      class="logo-icon"
                    />
                    <span>Google</span>
                  </button>
                  <button
                    class="btn w-100 text-start"
                    :class="
                      selectedService === 'Facebook'
                        ? 'btn-primary'
                        : 'btn-outline-secondary'
                    "
                    v-on:click="selectService('Facebook')"
                  >
                    <BaseIcon name="facebook" type="social" class="logo-icon" />
                    <span>Facebook</span>
                  </button>
                  <button
                    class="btn w-100 text-start"
                    :class="
                      selectedService === 'TikTok'
                        ? 'btn-primary'
                        : 'btn-outline-secondary'
                    "
                    v-on:click="selectService('TikTok')"
                  >
                    <BaseIcon name="tiktok" type="social" class="logo-icon" />
                    <span>TikTok</span>
                  </button>
                  <button
                    class="btn w-100 text-start"
                    :class="
                      selectedService === 'Trustpilot'
                        ? 'btn-primary'
                        : 'btn-outline-secondary'
                    "
                    v-on:click="selectService('Trustpilot')"
                  >
                    <BaseIcon
                      name="trustpilot"
                      type="social"
                      class="logo-icon"
                    />
                    <span>Trustpilot</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-lg-9">
              <div
                v-if="addNew && selectedService !== null"
                class="m-5 p-5 text-center"
              >
                <div class="bold-40">Connect an account</div>
                <p>Manage your data from Prodyflow</p>
                <button class="btn btn-type-2 mt-4" v-on:click="login">
                  <span>Connect account</span>
                </button>
              </div>
              <div v-if="selectedPage === null && selectedService === null">
                <div class="mb-5">
                  <SwitchButtons
                    v-model="activeMenu"
                    col-class="col-12 col-md col-lg-3"
                    :buttons="[
                      { name: 'Ratings', value: 'ratings' },
                      { name: 'Posts', value: 'posts' },
                      { name: 'Tagged', value: 'tagged' },
                      { name: 'Ads', value: 'ads' },
                    ]"
                  />
                </div>
                <section v-if="activeMenu == 'ratings'">
                  <div class="row mb-5">
                    <div class="col-4">
                      <BaseIcon
                        name="star-filled"
                        v-for="i in Math.round(rating.total)"
                        :key="i"
                      />
                      <BaseIcon
                        name="star"
                        v-for="i in 5 - Math.round(rating.total)"
                        :key="i"
                      />
                    </div>
                    <div class="col-4 text-center">
                      <h2 class="bold-24 text-default mb-2">
                        {{ rating.total }}
                      </h2>
                      <h2 class="bold-19 text-default">Total rating</h2>
                    </div>
                    <div class="col-4 text-center">
                      <h2 class="bold-24 text-black mb-2">
                        {{ rating.count }}
                      </h2>
                      <h2 class="bold-19 text-black">Rating count</h2>
                    </div>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-1">
                        <span class="regular-12 text-black mt-3">
                          Show in website
                        </span>
                      </div>
                    </div>
                    <div
                      v-for="review in reviews"
                      :key="review.id"
                      class="row d-flex align-items-center mt-2"
                    >
                      <div class="col-auto">
                        <label class="cstm-check d-flex">
                          <input
                            type="checkbox"
                            v-model="review.show_in_website"
                            v-on:change="reviewShowInWebsite(review)"
                            :value="true"
                          />
                          <span class="checkmark mt-3"></span>
                        </label>
                      </div>
                      <div class="col-auto">
                        {{ formatDate(review.posted_at) }}
                      </div>
                      <div class="col-auto">
                        <BaseIcon
                          :name="getLogo(review.integration_service)"
                          type="social"
                          class="logo-icon"
                        />
                      </div>
                      <div class="col-2">
                        <template v-if="review.rate_max === 2">
                          <BaseIcon
                            :name="review.rate == 2 ? 'like' : 'dislike'"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            name="star-filled"
                            v-for="i in review.rate"
                            :key="i"
                          />
                          <BaseIcon
                            name="star"
                            v-for="i in review.rate_max - review.rate"
                            :key="i"
                          />
                        </template>
                      </div>
                      <div class="col">
                        {{ review.text }}
                      </div>
                      <div class="col-auto">
                        {{ review.username }}
                        <img
                          class="avatar-image"
                          :src="review.avatar"
                          v-if="review.avatar"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section v-if="activeMenu == 'posts'">
                  <div>
                    <div v-for="post in posts" :key="post.id" class="mt-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-auto">
                          <BaseIcon
                            :name="getLogo(post.integration_service)"
                            type="social"
                            class="logo-icon"
                          />
                        </div>
                        <div class="col-2">
                          {{ post.username }}
                        </div>
                        <div class="col-2">
                          {{ formatDate(post.posted_at) }}
                        </div>
                      </div>
                      <div>
                        {{ post.text }}
                        <ImageAnnotorious
                          v-if="post.media_url"
                          :src="post.media_url"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section v-if="activeMenu == 'tagged'">
                  <div>
                    <div
                      v-for="post in mentions"
                      :key="post.id"
                      class="mt-3 mb-3 pb-3 border-bottom"
                    >
                      <div class="row d-flex align-items-center">
                        <div class="col-auto">
                          <BaseIcon
                            :name="getLogo(post.integration_service)"
                            type="social"
                            class="logo-icon"
                          />
                        </div>
                        <div class="col-2">
                          {{ post.username }}
                        </div>
                        <div class="col-2">
                          {{ formatDate(post.posted_at) }}
                        </div>
                      </div>
                      <div>
                        {{ post.text }}
                        <img
                          v-if="post.media_url"
                          :src="post.media_url"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import ws from "@/modules/ws";
import http from "@/modules/http";
import date from "@/modules/date";
import BaseIcon from "../../../components/icons/BaseIcon.vue";
import SwitchButtons from "../../../components/inputs/SwitchButtons.vue";
import ImageAnnotorious from "../../../components/ImageAnnotorious.vue";

export default {
  name: "Statistics",
  components: { ImageAnnotorious, SwitchButtons, BaseIcon },
  data() {
    return {
      store: useStore(),
      services: [],
      pages: [],
      data: [],
      reviews: [],
      posts: [],
      mentions: [],
      rating: {},
      activeMenu: "ratings",
      selectedService: null,
      selectedPage: null,
      addNew: false,
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    this.loadData();

    ws.init();

    ws.subscribe("add", "contactMessage", (e) => {
      if (e.session !== this.sessionId) {
        this.messages.push(e.object);
        this.tableKey++;
      }
    });
  },
  methods: {
    getLogo(name) {
      switch (name) {
        case "google":
          return "google-maps";
        default:
          return name;
      }
    },
    getTypeClass(type) {
      switch (type) {
        case 1:
          return "primary";
        case 2:
          return "failed";
        case 3:
          return "light";
        default:
          return "";
      }
    },
    getTypeName(type) {
      switch (type) {
        case 1:
          return "Feed";
        case 2:
          return "Ads";
        case 3:
          return "Location";
        default:
          return "";
      }
    },
    formatDate(val) {
      return date.format(val, true, false);
    },
    login() {
      http
        .fetch(`/service-integrations/login/${this.selectedService}`)
        .then((data) => {
          location.href = data.redirect;
        });
    },
    loadData() {
      let url = "/service-integrations";
      http.fetch(url).then((data) => {
        this.services = data.services;
        this.pages = data.pages;
        this.reviews = data.reviews;
        this.rating = data.rating;
        this.posts = data.posts;
        this.mentions = data.mentions;
      });
    },
    selectService(service, page) {
      this.selectedService = service;
      this.selectedPage = page;
      this.addNew = false;
      if (this.services[service]) {
        this.loadBasicData();
      } else {
        this.addNew = true;
      }
    },
    loadBasicData() {
      http
        .fetch(`/service-integrations/basic/${this.selectedService}`)
        .then((data) => {
          this.data[this.selectedService] = data;
        });
    },
    reviewShowInWebsite(review) {
      http.fetch(`/service-integrations/reviews/${review.id}`, {
        show_in_website: review.show_in_website,
      });
    },
  },
};
</script>
