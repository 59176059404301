<template>
  <div
    class="toast toast-selector fade popup text-start"
    aria-live="assertive"
    aria-atomic="true"
    ref="modal"
    :style="{ top: positionY + '%', left: positionX + '%' }"
  >
    <div class="toast-header">
      <strong class="me-auto"> Select product </strong>
      <button
        type="button"
        class="btn-close"
        v-on:click="closeModal"
        :aria-label="$t(`orderChange.close`)"
      ></button>
    </div>
    <div class="toast-body" ref="body">Product selector</div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "ProductSelectorToast",
  props: {
    modelValue: Object,
    show: Boolean,
    positionY: Number,
    positionX: Number,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    return {
      modelValueData:
        typeof this.modelValue === "object"
          ? Object.assign({}, this.modelValue)
          : {},
    };
  },
  mounted() {
    let myModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }
    this.calcVatPrice();
  },
  methods: {
    closeModal(checkFocus = false) {
      if (checkFocus) {
        if (this.$refs.body.contains(document.activeElement)) {
          return;
        }
        const _this = this;
        setTimeout(function () {
          if (_this.$refs.body.contains(document.activeElement)) {
            return;
          }
          _this.$emit("update:modelValue", _this.modelValueData);
          _this.$emit("change");
          _this.$emit("update:show", false);
        }, 400);
      } else {
        this.$emit("update:modelValue", this.modelValueData);
        this.$emit("change");
        this.$emit("update:show", false);
      }
    },
  },
  watch: {
    modelValueData: {
      deep: true,
      handler() {
        //this.$emit("update:modelValue", this.value);
        this.calcVatPrice();
      },
    },
    show() {
      let myModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
      if (this.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    },
  },
  components: {},
};
</script>
<style scoped>
.toast-selector {
  position: absolute;
  transform: translate(-50%, -50%);
}
</style>
